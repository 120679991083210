import { FC, useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useDirectChatPath } from '@hooks_iqfu/public/useDirectChatPath';
import { useInjectLeasebackCaution } from '@hooks_iqfu/public/useInjectLeasebackCaution';
import { useLikedCompanies } from '@hooks_iqfu/public/useLikedCompanies';

import { displayFloatingPanelMovieAtom } from '@recoil_iqfu/atoms/public/displayFloatingPanelMovie';
import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';
import userUuidValue from '@recoil_iqfu/atoms/public/user_uuid';

import YouTubeEmbed from '@ad_lp/cm/components/YouTubeEmbed/youtube_embed';
import { companyDetailPagePathRegex } from '@constants_iqfu';
import { companyIDRegex } from '@constants_iqfu';
import { cmVideoId } from '@constants_iqfu';

import styles from './FloatingStartSellingChatPanel.module.scss';

type Props = {
  pageLabel: string;
  isLiked?: boolean;
};

const FloatingStartSellingChatPanel: FC<Props> = ({ pageLabel, isLiked }) => {
  const router = useRouter();
  const userUuid = useRecoilValue(userUuidValue);
  const isLoggedIn = useRecoilValue(sellerIsLoggedIn);

  const showFloatingThreshold = 300;

  const isInCompaniesPage = router.asPath.includes('/companies');

  const { dispatchAddLikedCompaniesAction } = useLikedCompanies();
  const injectLeasebackCautions = useInjectLeasebackCaution();

  //session storageで管理
  //一度閉じられるとページ遷移後movieは非表示でCVパネルのみ表示
  const [displayFloatingPanelMovie, setDisplayFloatingPanelMovie] = useRecoilState(displayFloatingPanelMovieAtom);
  const [showFloating, setShowFloating] = useState(false);
  const [hasClickedClose, setHasClickedClose] = useState(false);

  const getDirectChatPath = useDirectChatPath({ floatingLink: true });

  useEffect(() => {
    setShowFloating(false);
    setHasClickedClose(false);
  }, [router.asPath]);

  const FloatingCloseHandler = () => {
    setShowFloating(false);
    setHasClickedClose(true);
    setDisplayFloatingPanelMovie(false);
  };

  const isInCompanyDetailPage = companyDetailPagePathRegex.test(router.asPath);
  const addCurrentCompanyToLikedCompanies = () => {
    const companyId = companyIDRegex.exec(router.asPath)?.[1];
    if (companyId && !isLiked) {
      dispatchAddLikedCompaniesAction({
        isLoggedIn,
        userUuid,
        companyId: Number(companyId),
        component: 'FloatingStartSellingChatPanel',
        pageLabel,
      });
    }
  };

  // 一定量スクロール後に表示
  const [scrollY, setScrollY] = useState(300);
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showFloatingThreshold && !hasClickedClose) {
      if (scrollY > showFloatingThreshold) {
        setShowFloating(true);
      }
      // if (scrollY === 0) {
      //   setShowFloating(false);
      // }
    }
  }, [scrollY]);

  const FloatingStartButtonHandler = () => {
    const stopProcessing = injectLeasebackCautions();
    if (stopProcessing) return;

    if (isInCompanyDetailPage) {
      addCurrentCompanyToLikedCompanies();
    }

    router.push(getDirectChatPath());
  };

  //recoilのhydration error回避用
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <>
      {showFloating && isInCompaniesPage && (
        <div className={`${styles.floating_container} ${!displayFloatingPanelMovie ? styles.without_movie : ''}`}>
          <button className={styles.floating_close} onClick={() => FloatingCloseHandler()}>
            <img
              data-gtm-click="FloatingStartSellingChatPanel_close"
              src="/images/iqfu/public/mbs/floating_close.svg"
              alt="閉じる"
            />
          </button>
          {hasMounted && displayFloatingPanelMovie && (
            <div className={styles.cm_movie_wrapper}>
              <div className={styles.cm_movie_badge}>
                CMは
                <br />
                こちら
              </div>
              <div className={styles.cm_movie}>
                <YouTubeEmbed videoId={cmVideoId} event="click_video_pc_floating" />
              </div>
            </div>
          )}
          <p className={styles.floating_lead_2nd}>
            <img src="/images/iqfu/public/focus_icon.svg" alt="" className={styles.fucus_icon_before} />
            <span>チャットで気軽に無料相談</span>
            <img src="/images/iqfu/public/focus_icon.svg" alt="" className={styles.fucus_icon_after} />
          </p>

          <button
            className={styles.floating_start_button}
            onClick={() => {
              FloatingStartButtonHandler();
            }}
          >
            売却相談スタート！（無料）
          </button>
        </div>
      )}

      {showFloating && !isInCompaniesPage && (
        <div className={`${styles.floating_container} ${!displayFloatingPanelMovie ? styles.without_movie : ''}`}>
          <button className={styles.floating_close} onClick={() => FloatingCloseHandler()}>
            <img
              data-gtm-click="FloatingStartSellingChatPanel_close"
              src="/images/iqfu/public/mbs/floating_close.svg"
              alt="閉じる"
            />
          </button>
          {hasMounted && displayFloatingPanelMovie && (
            <div className={styles.cm_movie_wrapper}>
              <div className={styles.cm_movie_badge}>
                CMは
                <br />
                こちら
              </div>
              <div className={styles.cm_movie}>
                <YouTubeEmbed videoId={cmVideoId} event="click_video_pc_floating" />
              </div>
            </div>
          )}
          <p className={styles.floating_lead}>
            <span>24時間365日相談無料</span>
          </p>
          <p className={styles.floating_lead_2nd}>
            <img src="/images/iqfu/public/focus_icon.svg" alt="" className={styles.fucus_icon_before} />
            <span>チャットで気軽に無料相談</span>
            <img src="/images/iqfu/public/focus_icon.svg" alt="" className={styles.fucus_icon_after} />
          </p>

          <button
            className={styles.floating_start_button}
            onClick={() => {
              FloatingStartButtonHandler();
            }}
          >
            売却相談スタート！（無料）
          </button>
        </div>
      )}
    </>
  );
};

export default FloatingStartSellingChatPanel;
