import React from 'react';
import { FC } from 'react';

import YouTube from 'react-youtube';

import { sendEventToGTM } from '@utils_common/sendEventToGTM';

import styles from './youtube_embed.module.scss';


type YouTubeEmbedProps = {
  videoId: string;
  event: string;
};

const YouTubeEmbed: FC<YouTubeEmbedProps> = ({ videoId, event }) => {
  const handleVideoPlay = () => {
    sendEventToGTM({ event });
  };

  return <YouTube className={styles.youtube_embed_container} videoId={videoId} onPlay={handleVideoPlay} />;
};

export default YouTubeEmbed;
