import { useRecoilValue, useSetRecoilState } from 'recoil';

import { leaseBackCautionDialogOpenAtom } from '@recoil_iqfu/atoms/public/leaseback_cautions_dialog_open';
import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';

import { useAuth } from '@contexts_common/authContext';


export const useInjectLeasebackCaution = () => {
  const isLoggedIn = useRecoilValue(sellerIsLoggedIn);
  const { sellerServices } = useAuth();
  const setLeasebackCautionDialogOpen = useSetRecoilState(leaseBackCautionDialogOpenAtom);

  const injectLeasebackCautions = () => {
    let stopProcessing = false;
    if (isLoggedIn && !sellerServices?.includes('selling')) {
      setLeasebackCautionDialogOpen(true);
      stopProcessing = true;
    }
    return stopProcessing;
  };

  return injectLeasebackCautions;
};
