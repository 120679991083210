import { FC } from 'react';

import { useMediaQuery } from '@mui/material';

import FloatingStartSellingChatButton from '@components_iqfu/public/floating_cv/floating_start_selling_chat_button/FloatingStartSellingChatButton';
import FloatingStartSellingChatPanel from '@components_iqfu/public/floating_cv/floating_start_selling_chat_panel/FloatingStartSellingChatPanel';

import { MediaQuery } from '@constants_common';

type Props = {
  pageLabel: string;
  showFloatingThreshold?: number;
  isLiked?: boolean;
};

const FloatingCV: FC<Props> = ({ pageLabel, showFloatingThreshold, isLiked }) => {
  const isMobile = useMediaQuery(MediaQuery.lg);
  return (
    <>
      {isMobile ? (
        <FloatingStartSellingChatButton
          pageLabel={pageLabel}
          showFloatingThreshold={showFloatingThreshold}
          isLiked={isLiked}
        />
      ) : (
        <FloatingStartSellingChatPanel pageLabel={pageLabel} isLiked={isLiked} />
      )}
    </>
  );
};

export default FloatingCV;
