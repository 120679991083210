import { FC, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useRecoilValue } from 'recoil';

import { useDirectChatPath } from '@hooks_iqfu/public/useDirectChatPath';
import { useInjectLeasebackCaution } from '@hooks_iqfu/public/useInjectLeasebackCaution';
import { useLikedCompanies } from '@hooks_iqfu/public/useLikedCompanies';

import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';
import userUuidValue from '@recoil_iqfu/atoms/public/user_uuid';

import { companyDetailPagePathRegex } from '@constants_iqfu';
import { companyIDRegex } from '@constants_iqfu';

import styles from './FloatingStartSellingChatButton.module.scss';

type Props = {
  pageLabel: string;
  showFloatingThreshold?: number;
  isLiked?: boolean;
};

const FloatingStartSellingChatButton: FC<Props> = ({ pageLabel, showFloatingThreshold, isLiked }) => {
  const router = useRouter();

  const userUuid = useRecoilValue(userUuidValue);
  const isLoggedIn = useRecoilValue(sellerIsLoggedIn);
  const injectLeasebackCautions = useInjectLeasebackCaution();
  const { dispatchAddLikedCompaniesAction } = useLikedCompanies();

  const isInCompanyDetailPage = companyDetailPagePathRegex.test(router.asPath);
  const isInCompaniesPage = router.asPath.includes('/companies');

  const MICROCOPY_AB_PATTERN = {
    0: { text: '無料&しつこい営業なし', buttonClass: styles.start_button },
    1: { text: 'チャットで相談＆しつこい営業なし！', buttonClass: styles.start_button_micro_copy_ver2 },
  } as const;
  type MICROCOPY_AB_KEY = keyof typeof MICROCOPY_AB_PATTERN;
  const [microCopyAbFlag] = useState<MICROCOPY_AB_KEY>(Math.random() < 0.5 ? 0 : 1);
  const renderPattern = MICROCOPY_AB_PATTERN[microCopyAbFlag];

  const getDirectChatPath = useDirectChatPath({ floatingLink: true });

  const addCurrentCompanyToLikedCompanies = () => {
    const companyId = companyIDRegex.exec(router.asPath)?.[1];
    if (companyId && !isLiked) {
      dispatchAddLikedCompaniesAction({
        isLoggedIn,
        userUuid,
        companyId: Number(companyId),
        component: 'FloatingStartSellingChatPanel',
        pageLabel,
      });
    }
  };

  const FloatingStartButtonHandler = () => {
    const stopProcessing = injectLeasebackCautions();
    if (stopProcessing) return;

    if (isInCompanyDetailPage) {
      addCurrentCompanyToLikedCompanies();
    }

    router.push(getDirectChatPath());
  };

  // 一定量スクロール後に表示
  const [scrollY, setScrollY] = useState(300);
  const [showFloating, setShowFloating] = useState(false);
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showFloatingThreshold) {
      if (scrollY > showFloatingThreshold) {
        setShowFloating(true);
      }
      if (scrollY === 0) {
        setShowFloating(false);
      }
    }
  }, [scrollY]);

  //isLoggedInのrecoilのstateがssr時の初期値とstorageの値で変わりhydration errorになるので、マウント後にisLoggedInの値を確認する
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);

  return (
    <>
      {isMounted && !isLoggedIn && isInCompaniesPage && (
        <div className={`${styles.container} ${showFloating ? '' : styles.hidden}`}>
          <div className={styles.start_button_wrap}>
            <div className={styles.micro_copy_wrap}>
              <span className={styles.micro_copy}>{renderPattern.text}</span>
            </div>

            <button
              className={styles.start_button}
              onClick={() => {
                FloatingStartButtonHandler();
              }}
            >
              売却相談スタート！（無料）
            </button>
          </div>
        </div>
      )}

      {isMounted && !isLoggedIn && !isInCompaniesPage && (
        <div className={`${styles.container} ${showFloating ? '' : styles.hidden}`}>
          <div className={styles.start_button_wrap}>
            <div className={styles.micro_copy_wrap}>
              <span className={styles.micro_copy}>無料＆チャットで気軽に相談</span>
            </div>

            <button
              className={styles.start_button}
              onClick={() => {
                FloatingStartButtonHandler();
              }}
            >
              売却相談をはじめる（無料）
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FloatingStartSellingChatButton;
