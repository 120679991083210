import { useRouter } from 'next/router';

import { useRecoilValue } from 'recoil';

import { useChatProperty } from '@hooks_iqfu/chat/useChatProperty';

import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';

import { useAuth } from '@contexts_common/authContext';

export const useDirectChatPath = ({ floatingLink = false } = {}) => {
  const isLoggedIn = useRecoilValue(sellerIsLoggedIn);
  const { useGetChatProperties } = useChatProperty();

  const { data: chatPropertieseList } = useGetChatProperties();
  const router = useRouter();
  const isInCompaniesPage = router.asPath.includes('/companies');

  const { sellerServices } = useAuth();
  const hasSellingService = sellerServices?.includes('selling');

  const getDirectChatPath = () => {
    if (isLoggedIn && hasSellingService && chatPropertieseList && chatPropertieseList?.chat_properties.length > 0) {
      return `/chat/properties/request/${chatPropertieseList.chat_properties?.[0].id}`;
    } else if (isLoggedIn) {
      return '/chat/talk';
    } else if (floatingLink && !isInCompaniesPage) {
      return '/signup_inquiry';
    } else {
      return '/signup';
    }
  };

  return getDirectChatPath;
};
