import { FC, memo } from 'react';

import styles from './SectionLeadLeftAlign.module.scss';

const SectionLeadLeftAlign: FC = memo(({ children }) => {
  return <h2 className={`${styles.lead}`}>{children}</h2>;
});

SectionLeadLeftAlign.displayName = 'SectionLeadLeftAlign';

export default SectionLeadLeftAlign;
