import * as Sentry from '@sentry/browser';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';

import { ChatPropertyDetail } from '@type-def_iqfu/chat/ChatPropertyDetail';
import { ChatPropertyListItem } from '@type-def_iqfu/chat/ChatPropertyListItem';

const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

export const useChatProperty = () => {
  const [cookies] = useCookies(['expiry', 'access-token', 'client', 'uid', 'user-type']);

  const createSellerChatProperty = async (payload: any) => {
    const url = `${backendBasePath}/seller/chat_properties`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
        'Content-Type': 'application/json',
      },
      body: payload,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('payload', payload);
          Sentry.captureException(error);
        });
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const updateSellerChatProperty = async (payload: any, propertyId: number) => {
    const url = `${backendBasePath}/seller/chat_properties/${propertyId}`;

    const result = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
        'Content-Type': 'application/json',
      },
      body: payload,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('payload', payload);
          Sentry.captureException(error);
        });
        console.error('通信に失敗しました', error);
      });

    return result;
  };

  const getSellerChatPropertiesList = async (): Promise<{ chat_properties: ChatPropertyListItem[] }> => {
    const url = `${backendBasePath}/seller/chat_properties`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const getSellerChatPropertiesDetail = async (chat_property_id: string): Promise<ChatPropertyDetail | void> => {
    const url = `${backendBasePath}/seller/chat_properties/${chat_property_id}`;
    //呼び出せる前にStringになっているからundefined弾くにはこうするしかない
    //string[] | string を stringに変換するためにそうなっている
    //余裕できたらリファクタリングする
    if (chat_property_id === 'undefined') return;
    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const addCompanyToChatPropertyBySeller = async (propertyId: number, requestParams: any) => {
    const url = `${backendBasePath}/seller/chat_properties/${propertyId}/add_company`;

    const payload = JSON.stringify(requestParams);
    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: payload,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('payload', payload);
          Sentry.captureException(error);
        });
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const useGetChatProperties = () => {
    const isLoggedIn = useRecoilValue(sellerIsLoggedIn);
    const getChatPropertiesQuery = ['getSellerChatPropertiesList', isLoggedIn];
    return useQuery(getChatPropertiesQuery, () => getSellerChatPropertiesList(), {
      staleTime: 1000,
      enabled: isLoggedIn,
    });
  };

  return {
    createSellerChatProperty,
    updateSellerChatProperty,
    getSellerChatPropertiesList,
    getSellerChatPropertiesDetail,
    addCompanyToChatPropertyBySeller,
    useGetChatProperties,
  };
};
